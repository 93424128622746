<template>
	<!-- 认养配送订单 -->
	<div class="v2-adopt-delivery-order">
		<div class="el-content mb12">
			<div class="kd-pacel">搜索参数</div>
			<div>
				<span>订单状态：</span>
				<a-button-group>
					<a-button @click="changeStatus(-1)" :type="search.delivery_status==-1 ? 'primary':'default'">全部</a-button>
					<a-button @click="changeStatus(0)" :type="search.delivery_status==0 ? 'primary':'default'">未发货</a-button>
					<a-button @click="changeStatus(1)" :type="search.delivery_status==1 ? 'primary':'default'">配送中</a-button>
					<a-button @click="changeStatus(2)" :type="search.delivery_status==2 ? 'primary':'default'">已完成</a-button>
				</a-button-group>
			</div>
			<div style="margin-top: 12px;">
				<span>批量操作：</span>
				<a-space>
					<com-upload-btn :custom-upload="true" btnText="批量发货" @uploadChange="handleChange"></com-upload-btn>
					<a-button @click="comfirmBatchOrder">确认收货</a-button>
					<a-button  @click="outputOrder">导出订单</a-button>
					<com-print-face-order
						btn-type="default"
						:type="3" 
						:order-id="ids">
					</com-print-face-order>
				</a-space>
			</div>
		</div>
		<div class="el-content">
			<div class="kd-pacel">列表</div>
			<a-table :data-source="info.list" :pagination="false" row-key="id" :columns="[
				{title:'每期编号',dataIndex:'cycle_number',slots:{customRender:'cycle_number'}},
				{title:'收货信息',dataIndex:'delivery',slots:{customRender:'delivery'}},
				{title:'配送信息',dataIndex:'send',slots:{customRender:'send'}},
				{title:'状态',dataIndex:'status',slots:{customRender:'status'}},
				{title:'物流信息',dataIndex:'express',slots:{customRender:'express'}},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}}
			]" :row-selection="{ selectedRowKeys: ids, onChange:(e)=>{ids = e}  }">
				<template #cycle_number="{record}">
					<div>订单ID：{{record.order_id }}</div>
					<div>每期编号：{{record.cycle_number}}</div>
				</template>
			    <template #delivery="{record}">
					<div style="font-weight: bold;font-size: 16px;"> {{record.name}}</div>
					<div> {{record.phone }}</div>
					<div> {{record.address}}</div>
				</template>
				<template #express="{record}">
					<div v-if="record.express_company">{{record.express_company}}</div>
					<div v-if="record.express_no">{{record.express_no}}</div>
					<div v-if="record.runing">
						<a-tooltip>
							<template #title>
								<template v-if="record.runing.run_type == 'dada'">
									<div>配送员：{{record.runing.dm_name}}</div>
									<div>配送员电话：{{record.runing.dm_mobile}}</div>
								</template>
								<template v-else>
									<div v-if="record.runing.persion">配送员：{{record.runing.persion.name}}</div>
									<div v-if="record.runing.persion">配送员电话：{{record.runing.persion.mobile}}</div>
								</template>
								<div>取货时间：{{record.runing.pickup_time || '--'}}</div>
								<div>送达时间：{{record.runing.confirm_time || '--'}}</div>
								<div v-if="record.runing.cancel_reason">取消理由：{{record.runing.cancel_reason}}</div>
							</template>
							<a-tag color="#33CCCC" v-if="record.runing.run_type == 'ziying'">商家配送</a-tag>
							<a-tag color="#33CCCC" v-if="record.runing.run_type == 'dada'">达达配送</a-tag>
						</a-tooltip>
						
						<p v-if="record.runing.persion">配送员：{{record.runing.persion.name}} ,{{record.runing.persion.mobile}}</p>
						<div v-if="record.runing.cancel_reason" style="color: red;">取消理由：{{record.runing.cancel_reason}}</div>
					</div>
				</template>
			
				<template #send="{record}">
					<div v-if="record.order.send_method == 0">配送时间：{{record.send_time || "未定"}}</div>
					<div>配送方式：
						<a-tag color="#FF0066" v-if="record.order.send_method == 1">自提</a-tag>
						<a-tag color="#33CCCC" v-if="record.order.send_method == 0">快递</a-tag>
					</div>
					
					<div v-if="record.order.send_method == 1 && record.order.outlet">
						<div>提货门店：{{ record.order.outlet.name}}</div>
						<div>门店电话：{{ record.order.outlet.mobile}}</div>
						<div>门店地址：{{ record.order.outlet.address}}</div>
					</div>
				</template>
				
				<template #status="{record}">
					<a-tag color="#999" v-if="record.delivery_status == 0">
						{{ record.order.send_method == 0 ?'未发货' :'未提货'}}
					</a-tag>
					<a-tag color="#33CCCC" v-if="record.delivery_status == 1">配送中</a-tag>
					<a-tag color="#00CC66" v-if="record.delivery_status == 2">已完成</a-tag>
				</template>
				<template #action="{record}">
					<a-space>
						<template
							 v-if="record.delivery_status == 0 " 
							 v-has="{action:'wxapp_adopt_order_send',plat:isShop}">
							 <com-deliver-goods
							 	btn-type="primary"
							 	btn-size="small" 
							 	:id="record.id" 
							 	:type="3" 
							 	:is-shop="isShop" 
							 	@success="()=>{getAdoptDeliveryOrder(info.page,info.limit)}">
							 </com-deliver-goods>
						</template>
						<a-button
							v-has="{action:'wxapp_adopt_delivery_confirm',plat:isShop}" 
							v-if="record.delivery_status == 1 " 
							type="primary" 
							@click="confirmCycleOrder(record.id)" 
							size="small">确认收货
						</a-button>
						<template
							v-has="{action:'wxapp_adopt_delivery_print_face',plat:isShop}" 
							v-if="record.delivery_status ==0">
							<com-print-face-order
								btn-type="primary"
								btn-size="small"
								:type="3" 
								:order-id="[record.id]"  
								@close="confirmCycleOrder(info.page,info.limit)">
							</com-print-face-order>
						</template>
						<template
							v-has="{action:'wxapp_adopt_delivery_logistice',plat:isShop}"
							v-if="record.delivery_status >=1 ">
							<com-logistics :order-type="3" :pk="record.id" btn-size="small" btn-type="primary" btn-text="查看物流"></com-logistics>
						</template>
					</a-space>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="info.page"
					:total="info.count"
					@showSizeChange="(p,e)=>{getAdoptDeliveryOrder(info.page,e)}"
					@change="(e)=>{getAdoptDeliveryOrder(e,info.limit)}"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import comDeliverGoods from '@/components/miniapp/com-deliver-goods.vue'
import comLogistics from '@/components/miniapp/com-logistics.vue'
import comPrintFaceOrder from '@/components/miniapp/com-print-face-order.vue'
import comUploadBtn from '@/components/public/com-upload-btn.vue'
import { reactive, toRefs } from 'vue'
import adoptModel from '@/api/adopt.js'
export default{
	name:"v2-adopt-delivery-order",
	components:{
		comLogistics,
		comDeliverGoods,
		comPrintFaceOrder,
		comUploadBtn
	},
	props:{
		isShop:{
			type:Number,
			default:0
		}
	},
	setup(){
		const _d = reactive({
			search:{ delivery_status:"-1"},
			info:{
				list:[],
				page:1,
				limit:10
			},
			ids:[],
		})
		getAdoptDeliveryOrder(1,_d.info.limit)
		function getAdoptDeliveryOrder(page,limit){
			adoptModel.getAdoptDeliveryOrder(page,limit,_d.search,res=>_d.info= {limit,...res})
		}
		
		function changeStatus(e){
			_d.search.delivery_status = e
			getAdoptDeliveryOrder(1,_d.info.limit)
		}

		const handleChange = (url)=>adoptModel.adoptDeliveryOrderBatch(url)
		const comfirmBatchOrder = ()=>adoptModel.handleAdoptOrder(_d.ids,'batch-confirm',"确认批量收货吗？",()=>{
			getAdoptDeliveryOrder(_d.info.page,_d.info.limit)
		})

		const confirmCycleOrder = (id)=>adoptModel.handleAdoptOrder(id,3,'确认该订单已经收货了吗？',()=>{
			getAdoptDeliveryOrder(_d.info.page,_d.info.limit)
		})

		const outputOrder = ()=>adoptModel.exportAdoptSendOrder()

		return{
			...toRefs(_d),
			getAdoptDeliveryOrder,
			handleChange,
			comfirmBatchOrder,
			confirmCycleOrder,
			outputOrder,
			changeStatus
		}
	}
}
</script>

<style lang="scss">
</style>
